import { useAdminCreateTaxRate } from "medusa-react"
import React, { useContext } from "react"
import { useForm } from "react-hook-form"
import Button from "../../../components/fundamentals/button"
import PlusIcon from "../../../components/fundamentals/icons/plus-icon"
import Modal from "../../../components/molecules/modal"
import LayeredModal, {
  LayeredModalContext,
} from "../../../components/molecules/modal/layered-modal"
import useNotification from "../../../hooks/use-notification"
import { getErrorMessage } from "../../../utils/error-messages"
import { nestedForm } from "../../../utils/nested-form"
import {
  EditTaxRateDetails,
  EditTaxRateFormType,
} from "./edit-tax-rate-details"
import { TaxRuleItem } from "./tax-rule-item"
import TaxRuleSelector from "./tax-rule-selector"

type NewTaxRateProps = {
  regionId: string
  onDismiss: () => void
}

type NewTaxRateFormData = {
  details: EditTaxRateFormType
  shipping_options: string[]
  product_types: string[]
  products: string[]
}

const NewTaxRate = ({ regionId, onDismiss }: NewTaxRateProps) => {
  const createTaxRate = useAdminCreateTaxRate()
  const form = useForm<NewTaxRateFormData>({
    defaultValues: {
      products: [],
      product_types: [],
      shipping_options: [],
    },
  })
  const { setValue, handleSubmit, watch } = form
  const notification = useNotification()

  const layeredModalContext = useContext(LayeredModalContext)

  const onSave = handleSubmit((data) => {
    createTaxRate.mutate(
      {
        product_types: data.product_types,
        products: data.products,
        shipping_options: data.shipping_options,
        name: data.details.name,
        code: data.details.code,
        rate: data.details.rate,
        region_id: regionId,
      },
      {
        onSuccess: () => {
          notification("Sucesso", "Taxa criada com sucesso", "success")
          onDismiss()
        },
        onError: (error) => {
          notification("Erro", getErrorMessage(error), "error")
        },
      }
    )
  })

  const [products, product_types, shipping_options] = watch([
    "products",
    "product_types",
    "shipping_options",
  ])

  const handleOverridesSelected = (rule) => {
    switch (rule.type) {
      case "products":
        setValue("products", rule.items)
        break
      case "product_types":
        setValue("product_types", rule.items)
        break
      case "shipping_options":
        setValue("shipping_options", rule.items)
        break
      default:
        break
    }
  }

  return (
    <LayeredModal
      isLargeModal
      context={layeredModalContext}
      handleClose={onDismiss}
    >
      <form onSubmit={onSave}>
        <Modal.Body>
          <Modal.Header handleClose={onDismiss}>
            <div>
              <h1 className="inter-xlarge-semibold">Adicionar taxa</h1>
            </div>
          </Modal.Header>
          <Modal.Content>
            <EditTaxRateDetails form={nestedForm(form, "details")} />
            <div>
              <p className="inter-base-semibold mb-base">Substitui</p>
              {(product_types.length > 0 ||
                products.length > 0 ||
                shipping_options.length > 0) && (
                  <div className="flex flex-col gap-base">
                    {products.length > 0 && (
                      <TaxRuleItem
                        onDelete={() =>
                          handleOverridesSelected({ type: "products", items: [] })
                        }
                        onEdit={() => {
                          layeredModalContext.push(
                            SelectOverridesScreen(
                              layeredModalContext.pop,
                              regionId,
                              handleOverridesSelected,
                              {
                                items: products,
                                type: "products",
                              }
                            )
                          )
                        }}
                        index={1}
                        name="Product Rules"
                        description={`Applies to ${products.length} product${products.length > 1 ? "s" : ""
                          }`}
                      />
                    )}
                    {product_types.length > 0 && (
                      <TaxRuleItem
                        onDelete={() =>
                          handleOverridesSelected({
                            type: "product_types",
                            items: [],
                          })
                        }
                        onEdit={() => {
                          layeredModalContext.push(
                            SelectOverridesScreen(
                              layeredModalContext.pop,
                              regionId,
                              handleOverridesSelected,
                              {
                                items: product_types,
                                type: "product_types",
                              }
                            )
                          )
                        }}
                        index={2}
                        name="Regras de tipo de produto"
                        description={`Se aplica para ${product_types.length
                          } tipo de produto${product_types.length > 1 ? "s" : ""}`}
                      />
                    )}
                    {shipping_options.length > 0 && (
                      <TaxRuleItem
                        onDelete={() =>
                          handleOverridesSelected({
                            type: "shipping_options",
                            items: [],
                          })
                        }
                        onEdit={() => {
                          layeredModalContext.push(
                            SelectOverridesScreen(
                              layeredModalContext.pop,
                              regionId,
                              handleOverridesSelected,
                              {
                                items: shipping_options,
                                type: "shipping_options",
                              }
                            )
                          )
                        }}
                        index={3}
                        name="Regras de opção de envio"
                        description={`Se aplica para ${shipping_options.length
                          } opções de entrega${shipping_options.length > 1 ? "s" : ""
                          }`}
                      />
                    )}
                  </div>
                )}
              {!(
                product_types.length > 0 &&
                products.length > 0 &&
                shipping_options.length > 0
              ) && (
                  <Button
                    type="button"
                    onClick={() => {
                      layeredModalContext.push(
                        SelectOverridesScreen(
                          layeredModalContext.pop,
                          regionId,
                          handleOverridesSelected
                        )
                      )
                    }}
                    className="w-full mt-base"
                    size="medium"
                    variant="secondary"
                  >
                    <PlusIcon /> Adicionar substituição
                  </Button>
                )}
            </div>
          </Modal.Content>
          <Modal.Footer>
            <div className="flex items-center justify-end w-full">
              <Button
                type="button"
                onClick={onDismiss}
                variant="ghost"
                size="small"
                className="w-eventButton justify-center"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="primary"
                size="small"
                className="w-eventButton justify-center"
              >
                Criar
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Body>
      </form>
    </LayeredModal>
  )
}

const SelectOverridesScreen = (
  pop,
  regionId,
  onOverridesSelected,
  options = {}
) => {
  return {
    title: "Add override",
    onBack: () => pop(),
    view: (
      <TaxRuleSelector
        regionId={regionId}
        onSubmit={onOverridesSelected}
        {...options}
      />
    ),
  }
}

export default NewTaxRate
