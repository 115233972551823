import { Product } from "@medusajs/medusa"
import { navigate } from "gatsby"
import {
  useAdminDeleteProduct,
  useAdminProductTypes,
  useAdminUpdateProduct,
} from "medusa-react"
import React from "react"
import { Controller } from "react-hook-form"
import TrashIcon from "../../../../components/fundamentals/icons/trash-icon"
import UnpublishIcon from "../../../../components/fundamentals/icons/unpublish-icon"
import Input from "../../../../components/molecules/input"
import Select from "../../../../components/molecules/select"
import StatusSelector from "../../../../components/molecules/status-selector"
import TagInput from "../../../../components/molecules/tag-input"
import TextArea from "../../../../components/molecules/textarea"
import BodyCard from "../../../../components/organisms/body-card"
import DetailsCollapsible from "../../../../components/organisms/details-collapsible"
import useNotification from "../../../../hooks/use-notification"
import { getErrorMessage } from "../../../../utils/error-messages"
import FormValidator from "../../../../utils/form-validator"
import { useGiftCardForm } from "../form/gift-card-form-context"

type InformationProps = {
  giftCard: Omit<Product, "beforeInsert">
}

const Information: React.FC<InformationProps> = ({ giftCard }) => {
  const {
    form: {
      register,
      setValue,
      control,
      formState: { errors },
    },
  } = useGiftCardForm()
  const notification = useNotification()
  const { product_types } = useAdminProductTypes(undefined, {
    cacheTime: 0,
  })

  const typeOptions =
    product_types?.map((tag) => ({ label: tag.value, value: tag.id })) || []

  const updateGiftCard = useAdminUpdateProduct(giftCard.id)
  const deleteGiftCard = useAdminDeleteProduct(giftCard.id)

  const setNewType = (value: string) => {
    const newType = {
      label: value,
      value,
    }

    typeOptions.push(newType)
    setValue("type", newType)

    return newType
  }

  const onUpdate = () => {
    updateGiftCard.mutate(
      {
        // @ts-ignore
        status: giftCard.status === "draft" ? "published" : "draft",
      },
      {
        onSuccess: () => {
          notification("Sucesso", "Gift Card atualizado com sucesso", "success")
        },
        onError: (error) => {
          notification("Erro", getErrorMessage(error), "error")
        },
      }
    )
  }

  const onDelete = () => {
    deleteGiftCard.mutate(undefined, {
      onSuccess: () => {
        navigate("/a/gift-cards")
        notification("Sucesso", "Gift card atualizado com sucesso", "success")
      },
      onError: (error) => {
        notification("Erro", getErrorMessage(error), "error")
      },
    })
  }

  return (
    <BodyCard
      title="Product information"
      subtitle="Gerencie as configurações do seu Gift Card"
      className={"h-auto w-full"}
      status={
        <GiftCardStatusSelector
          currentStatus={giftCard.status}
          onUpdate={onUpdate}
        />
      }
      actionables={[
        {
          label:
            giftCard?.status !== "published"
              ? "Publicar Gift Card"
              : "Cancelar publicação Gift Card",
          onClick: onUpdate,
          icon: <UnpublishIcon size="16" />,
        },
        {
          label: "Excluir vale-presente",
          onClick: onDelete,
          variant: "danger",
          icon: <TrashIcon size="16" />,
        },
      ]}
    >
      <div className="flex flex-col space-y-6">
        <div className="grid grid-cols-2 gap-large">
          <Input
            label="Nome"
            placeholder="Adicionar nome"
            required
            defaultValue={giftCard?.title}
            {...register("title", {
              required: FormValidator.required("Name"),
              pattern: FormValidator.whiteSpaceRule("Name"),
              minLength: FormValidator.minOneCharRule("Name"),
            })}
            errors={errors}
          />
          <Input
            label="Legenda"
            placeholder="Adicionar Legenda"
            {...register("subtitle", {
              pattern: FormValidator.whiteSpaceRule("Subtitle"),
              minLength: FormValidator.minOneCharRule("Subtitle"),
            })}
            errors={errors}
          />
          <TextArea
            label="Descrição"
            placeholder="Adicionar Descrição"
            {...register("description", {
              pattern: FormValidator.whiteSpaceRule("Description"),
              minLength: FormValidator.minOneCharRule("Description"),
            })}
            errors={errors}
          />
        </div>
        <DetailsCollapsible
          triggerProps={{ className: "ml-2" }}
          contentProps={{
            forceMount: true,
          }}
        >
          <div className="grid grid-cols-2 gap-large">
            <Input
              label="URL"
              placeholder="URL do produto"
              {...register("handle", {
                pattern: FormValidator.whiteSpaceRule("Handle"),
                minLength: FormValidator.minOneCharRule("Handle"),
              })}
              tooltipContent="URL do produto"
              errors={errors}
            />
            <Controller
              control={control}
              name="type"
              render={({ field: { value, onChange } }) => {
                return (
                  <Select
                    label="Tipo"
                    placeholder="Selecionar tipo..."
                    options={typeOptions}
                    onChange={onChange}
                    value={value}
                    isCreatable
                    onCreateOption={(value) => {
                      return setNewType(value)
                    }}
                    clearSelected
                  />
                )
              }}
            />
            <Controller
              name="tags"
              render={({ field: { onChange, value } }) => {
                return (
                  <TagInput
                    label="Tags (separados por vírgula)"
                    className="w-full"
                    placeholder="porção, bebidas..."
                    onChange={onChange}
                    values={value || []}
                  />
                )
              }}
              control={control}
            />
          </div>
        </DetailsCollapsible>
      </div>
    </BodyCard>
  )
}

const GiftCardStatusSelector = ({
  currentStatus,
  onUpdate,
}: {
  currentStatus: "draft" | "proposed" | "published" | "rejected"
  onUpdate: () => void
}) => {
  return (
    <StatusSelector
      activeState="Published"
      draftState="Draft"
      isDraft={currentStatus === "draft"}
      onChange={onUpdate}
    />
  )
}

export default Information
