import React from "react"
import { Controller } from "react-hook-form"
import Accordion from "../../../../components/organisms/accordion"
import RadioGroup from "../../../../components/organisms/radio-group"
import { usePriceListForm } from "../form/pricing-form-context"
import { PriceListType } from "../types"

const Type = () => {
  const { control } = usePriceListForm()

  return (
    <Accordion.Item
      forceMountContent
      required
      value="type"
      title="Tipo de lista de preços"
      description="Selecione o tipo da lista de preços"
      tooltip="Ao contrário dos preços de venda, uma substituição de preço não comunicará ao cliente que o preço faz parte de uma venda."
    >
      <Controller
        name="type"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => {
          return (
            <RadioGroup.Root
              value={value ?? undefined}
              onValueChange={onChange}
              className="flex items-center gap-base group-radix-state-open:mt-5 accordion-margin-transition"
            >
              <RadioGroup.Item
                value={PriceListType.SALE}
                className="flex-1"
                label="Oferta"
                description="Use isso se estiver criando preços para uma promoção."
              />
              <RadioGroup.Item
                value={PriceListType.OVERRIDE}
                className="flex-1"
                label="Substituir"
                description="Use isso para substituir preços."
              />
            </RadioGroup.Root>
          )
        }}
      />
    </Accordion.Item>
  )
}

export default Type
