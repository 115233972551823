import React from "react"
import InputField from "../../../../components/molecules/input"
import TextArea from "../../../../components/molecules/textarea"
import FormValidator from "../../../../utils/form-validator"
import { NestedForm } from "../../../../utils/nested-form"

export type GeneralFormType = {
  title: string
  subtitle: string | null
  handle: string
  material: string | null
  description: string | null
}

type Props = {
  form: NestedForm<GeneralFormType>
  requireHandle?: boolean
}

const GeneralForm = ({ form, requireHandle = true }: Props) => {
  const {
    register,
    path,
    formState: { errors },
  } = form

  return (
    <div>
      <div className="grid grid-cols-2 gap-x-large mb-small">
        <InputField
          label="Título"
          placeholder="Batata frita"
          required
          {...register(path("title"), {
            required: "Título é obrigatório",
            minLength: {
              value: 1,
              message: "Título deve ter ao menos um caractér",
            },
            pattern: FormValidator.whiteSpaceRule("Title"),
          })}
          errors={errors}
        />
        <InputField
          label="Legenda"
          placeholder="Sequinha e crocante..."
          {...register(path("subtitle"), {
            pattern: FormValidator.whiteSpaceRule("Subtitle"),
          })}
          errors={errors}
        />
      </div>
      <p className="inter-base-regular text-grey-50 mb-large">
        Dê ao seu produto um título curto e claro.
        <br />
        50-60 caracteres é o comprimento recomendado para motores de busca.
      </p>
      <div className="grid grid-cols-2 gap-x-large mb-large">
        <InputField
          label="Identificador"
          tooltipContent={
            !requireHandle
              ? "O identificador é a parte da URL que identifica o produto. Se não for especificado, será gerado a partir do título."
              : undefined
          }
          placeholder="batata-frita"
          required={requireHandle}
          {...register(path("handle"), {
            required: requireHandle ? "Identificador é obrigatório" : undefined,
            minLength: FormValidator.minOneCharRule("Handle"),
            pattern: FormValidator.whiteSpaceRule("Handle"),
          })}
          prefix="/"
          errors={errors}
        />
        <InputField
          label="Material"
          placeholder="100% batata orgânica"
          {...register(path("material"), {
            minLength: FormValidator.minOneCharRule("Material"),
            pattern: FormValidator.whiteSpaceRule("Material"),
          })}
          errors={errors}
        />
      </div>
      <TextArea
        label="Descrição"
        placeholder="Crocante e sequinha..."
        rows={3}
        className="mb-small"
        {...register(path("description"))}
        errors={errors}
      />
      <p className="inter-base-regular text-grey-50">
        Dê ao seu produto uma descrição curta e clara.
        <br />
        120-160 caracteres é o comprimento recomendado para motores de busca.
      </p>
    </div>
  )
}

export default GeneralForm
